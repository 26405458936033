import React, { useEffect, useState } from "react";
import { axiosInstance } from '../utils/axiosInstance';


import { Link } from "react-router-dom";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import Collapse from "@material-ui/core/Collapse";

import HomeIcon from "@material-ui/icons/Home";
import HotelIcon from '@material-ui/icons/Hotel';
import ProductIcon from '@material-ui/icons/LocalGroceryStoreOutlined';
import SettingsIcon from '@material-ui/icons/Settings';
import LocalShipping from '@material-ui/icons/LocalShipping';
import FolderIcon from '@material-ui/icons/FolderOpenOutlined';
import LocalOffer from '@material-ui/icons/LocalOfferOutlined';
import Money from '@material-ui/icons/Money';
import Web from '@material-ui/icons/Web';
import Home from '@material-ui/icons/Home';
import Settings from '@material-ui/icons/Settings';
import WhatsApp from '@material-ui/icons/WhatsApp';


import "./Sidebar.css";

import { LoginMenu } from '../auth/LoginMenu';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';





function TitleOption({ item }) {
    const [open, setOpen] = React.useState(false);

    const { label } = item;

    const handleClick = () => {
        setOpen(!open);
    };

    return (
        <div>
            <ListItem button onClick={handleClick}>
                <ListItemIcon style={{ width: '40px', minWidth:'40px'}}>
                        <i className="material-icons-outlined">{item.icon}</i>

                    </ListItemIcon>
                <ListItemText primary={label} disableTypography="true"/>
                    {open ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>

                    {
                        item.subMenuItems.map((subItem, index) => (
                            <Link to={subItem.to} style={{ color: "#44413b", textDecoration: "none" }}>
                                <ListItem button key={index} >
                                    <ListItemText primary={subItem.label} disableTypography="true" />
                                </ListItem>
                            </Link>
                        ))
                    }  

                      
                    </List>
            </Collapse>
            </div>
        );
}



function Sidebar({ depthStep, depth, expanded, menu }) {

    return (

        <div className="sidebar">
            <LoginMenu />
            <hr />

            <List
                component="nav"
                aria-labelledby="nested-list-subheader"           
            >
                {
                    menu.map((item, index) => (
                        <TitleOption item={item} key={ index} />
                    ))
                }   
            </List> 
        </div>
    );
}

export default Sidebar;
