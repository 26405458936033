import React, { Component } from 'react';
import { axiosInstance } from '../../utils/axiosInstance';

import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { Link } from "react-router-dom";
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import Grid from '@material-ui/core/Grid'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import store from '../../store';
import { Paper } from '@material-ui/core';

export class ProductCategoryForm extends Component {

    constructor(props) {
        super(props);

        this.state = {
            productCategoryID: 0,
            description: '',
        };;

        this.ProductCategoryID = this.props.match.params.value;
        this.isEdit = this.props.match.params.value != undefined;
    }


    async executeRESTCall(action) {

        const url = '/api/ProductCategory/';

        if (action === "POST") {
            await axiosInstance.post(url, this.state)
                .then(response => {

                    this.props.history.push('/FamiliasDeProductos');
                    store.dispatch({
                        type: "SNACKBAR_SUCCESS",
                        message: "Operación exitosa"
                    })

                }).catch(error => {
                    store.dispatch({
                        type: "SNACKBAR_SUCCESS",
                        message: error.response.data.message
                    })                   
                });
        }

        if (action === "PUT") {
            await axiosInstance.put(url, this.state)
                .then(response => {

                    this.props.history.push('/FamiliasDeProductos');
                    store.dispatch({
                        type: "SNACKBAR_SUCCESS",
                        message: "Operación exitosa"
                    })

                }).catch(error => {
                    store.dispatch({
                        type: "SNACKBAR_SUCCESS",
                        message: error.response.data.message
                    })
                });
        }     
    }
   

    onSubmit = e => {

        if (!this.isEdit) {
            this.executeRESTCall("POST");
        }
        else {
            this.executeRESTCall("PUT");
        }
    
        e.preventDefault();
    }


    onChange = (event, value) => {

        var str = event.target.value;
        str = str.replace(/\s+/g, '-').toLowerCase();

        this.setState({
            [event.target.name]: event.target.value,
            url: str,
        })
    }

    async componentDidMount() {

        if (this.isEdit) {
            await axiosInstance.get(`api/ProductCategory/GetByID/${this.ProductCategoryID}`).then(
                response => {
                    this.setState({
                        productCategoryID: response.data.id,
                        description: response.data.description,
                    });
                });
        }
    }

    CreateStyles() {
        return {            
            marginRight: '10px',
            marginBottom: '10px',
            float: 'right'
        }
    }

    HeaderTitle() {
        let title;

        if (this.isEdit) {
            title = 'Editando familia';
        }
        else {
            title ='Crea un nueva familia';
        }

        return (
            <div>
                <Grid container spacing={2}>
                    <Grid item xs={6} md={6}>
                        <h4>
                            {title}
                            <small >
                                <Breadcrumbs aria-label="breadcrumb">
                                    <Typography color="inherit">
                                        Productos
                                        </Typography>

                                    <Link color="inherit" to="/FamiliasDeProductos" >
                                        <Typography color="textPrimary">Familias de productos</Typography>
                                </Link>

                                </Breadcrumbs>
                            </small>
                        </h4>
                    </Grid>
                    <Grid item xs={6} md={6}>
                        <Link to="/FamiliasDeProductos">
                            <Button
                                variant="default"
                                color="default"
                                size="small"
                                startIcon={<CancelIcon />}
                                style={this.CreateStyles()}
                            >
                                Cancelar
                        </Button>
                        </Link>
                    </Grid>
                </Grid>

                <hr />
            </div>
        );

        
    }

    render() {


        return (

            <div>

                {this.HeaderTitle()}

               
            
                    <ValidatorForm ref="form"
                onSubmit={this.onSubmit}
                onError={errors => console.log(errors)}
                style={{ padding: "10px" }}>               

                        <Grid container spacing={2} >

                            <Grid item xs={12} sm={12} md={6} lg={4}>
                            <Paper style={{ padding: '15px' }} elevation={1}>

                                    <Grid container spacing={2} >
                       
                                        <Grid item sm={12} xs={12}>
                                        <TextValidator id="description"
                                                style={{ width: "100%" }}
                                                name="description"
                                                label="Nombre"
                                                variant="outlined"
                                                size="small"
                                                onChange={this.onChange}
                                                value={this.state.description}
                                                validators={['required', 'maxStringLength:50']}
                                        errorMessages={['El nombre de la familia es requerido', 'Máximo 50 carácteres']}
                                        autoFocus 
                                        autoComplete="off"
                                            />
                                        </Grid>
                                  
                    
                              

                                        <Grid item xs={12} md={12}>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                size="medium"
                                                startIcon={<SaveIcon />}
                                                type="submit"
                                                style={this.CreateStyles()}
                                            >
                                                Guardar
                                        </Button>

                                </Grid>
                          
                          
                
                                </Grid>

                                </Paper>
                            </Grid>
                        </Grid>

                    </ValidatorForm>
            </div>
        )
    }
}