import React, { Component } from 'react';
import { Done, Close } from '@material-ui/icons';

export class EnabledCell extends Component {
    constructor(props) {
        super(props);       
    }

    render() {
        let icon;

        if (this.props.enabled) {
            icon = <Done style={{ color: 'green' }} />;
        }
        else {
            icon = <Close style={{ color: 'red' }} />;
        }

        return (icon);
        
    }
}