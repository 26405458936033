import React, { Component } from 'react';


export class Page403 extends Component {


	render() {
		return (
			<h1>No tienes permisos para acceder</h1>
			);
	}
}