import React, { Fragment } from 'react';
import { Grid } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Chip from '@material-ui/core/Chip';
//connect to store



import Badge from '@material-ui/core/Badge';


import parse from 'html-react-parser';











class TopProductCardXs extends React.Component {




    renderPrice() {
        const { data } = this.props;


        
            return (
                <div>
                    
                    <Grid
                        container
                        justify="space-between"
                        alignItems="center"
                        style={{ marginTop: '15px', borderTop: '1px solid #eee', borderBottom: '1px solid #eee', paddingTop:'8px', paddingBottom:'8px' }}>
                        
                        <Typography inline variant="body1" align="left" className={"MuiTypography--subheading"} variant={"caption"} color="default" style={{ fontSize: '1.1em', fontWeight: '0', textDecoration: 'line-through' }}>
                            <small>PVP</small> ${data.pvpPrice.toFixed(2)}
                        </Typography>

                        <Typography inline variant="body1" color="secondary" align="right" style={{ fontSize: '1.1em', fontWeight: '900' }}>
                            -{data.biosintexDiscount}%
                        </Typography>

                        <Typography className={"MuiTypography--subheading"} variant={"caption"} color="primary" align="right" style={{ fontSize: '1.4em', fontWeight: '600' }}>
                            ${data.price}
                        </Typography>

                    </Grid>

                    <center>
                      
                        </center>


                    


                    </div>

                    );
        
	}

    renderButtonOptions() {
        const { data } = this.props;

      
            return (
                <div className="iron-btn-grp2 mb-sm-0 mb-5">


                            <div>
                                <br />


                         

                                <div>

                                    <h5 className="offer-color">Ofertas</h5>

                           
                                           
                                                <Grid container
                                                    
                                                    spacing={4}>
                                                    <Grid item xs={12}>
                                                        <center>
                                                        <Badge badgeContent={this.props.labelSecondText}  color="primary" className="test" style={{
                                                            margin: 'theme.spacing.unit * 2',
                                                            width: '85%',                                                       
                                                        }}>
                                            <Button fullWidth className="btn-rounded" style={{ fontSize: '1rem', backgroundColor: '#218f72', color:'#fff'}}>
                                                                {this.props.minimumAmount + " X " + this.props.pay}
                                                            </Button>
                                                        </Badge>

                                                    </center>
                                                    </Grid>
                                                

                                                </Grid>

                                   

                                </div>
                         


                    

                             
                       
                            </div>
                     
                </div>

            );
       
    }


   render() {
        const { data } = this.props;

       let discountBadge;
       let priceWithOutDiscount;

       
       if (data.haveDiscount) {
           discountBadge =
               <div className="iron-offer-badge-xs">
               <span>
                   {}
                   <Typography style={{
                       color: '#fff'
                       , fontSize: '1.5rem'
                       , fontWeight: '800'
                       , textAlign: 'left'
                   }}>
                        {data.discountLabelFirstText}
                   </Typography>

                  


                   </span>
                   {/*
                       data.discountLabelSecondText != null ?
                       <span className="font-medium base-color">
                           {data.discountLabelSecondText}
                           </span>
                           : null
                   */}
               </div>
         
               ;

           priceWithOutDiscount =
               <Typography className={"MuiTypography--subheading"} variant={"caption"} style={{ fontSize: '15px', color: '#444', fontWeight: '0' }}>
                   <span className="discount">
                      ${data.price}
                   </span>
               </Typography>;
       }

      return (
         <Fragment>
              {data !== null ?

                  <Card className="iron-product-item p-15 iron-shadow position-relative hover-box-shadow" style={{padding:'15px'}}>

                      <Grid container spacing={2}>
                          <Grid item xs={5}>
                              
                                  <CardMedia
                                      height="100"
                                      component="img"
                                      image={data.image}
                                      alt="das"
                                  />
                          
                          </Grid>
                          <Grid item xs={7}>
                              <CardContent className="iron-product-content p-0">

                                  <h5 className="">
                                      {parse(data.name)}
                                  </h5>

                                  <p style={{
                                      marginBottom: '0.6rem',

                                  }}
                                  >
                                      {data.description}
                                  </p>

                                  <p style={{
                                      color: '#999',
                                      marginBottom: '0.6rem',

                                  }}
                                  >
                                      {data.activeIngredient}
                                  </p>

                                  {}

                                  <p style={{ marginBottom: '0.6rem', color: '#339933' }}>
                                 {data.presentation}
                                      </p>





                              </CardContent>
                          </Grid>
                      </Grid>

                      <Grid container spacing={1}>
                          <Grid item xs={12}>

                              {
                                  this.renderPrice()
                              }



                              {
                                  this.renderButtonOptions()
                              }
                          </Grid>
                      </Grid>

                    <div>
                    
                    </div>

         
               
                 
                </Card>
               :
               null
            }
         </Fragment>
      )
   }
}



export default TopProductCardXs;



          