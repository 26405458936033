import React, { Component } from 'react';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { Link } from "react-router-dom";
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import axios from 'axios';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import Grid from '@material-ui/core/Grid'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import store from '../../store';
import { Paper } from '@material-ui/core';

import { getToken, initAxiosInterceptors } from '../../helpers/auth-helper';

export class SellerForm extends Component {

    constructor(props) {
        super(props);

        this.state = {
            id: 0,
            firstName: '',
            lastName: '',
            whatsAppNumber: '',
            codePlataforma: '',
            email: '',

        };;

        this.id = this.props.match.params.value;
        this.isEdit = this.props.match.params.value != undefined;
        this.onSubmit = this.onSubmit.bind(this);

    }


  
   

    async onSubmit(e) {
        if (!this.isEdit) {
            this.executeRESTCall("POST");
        }
        else {
            this.executeRESTCall("PUT");
        }

        e.preventDefault();
    }


    onChange = (event, value) => {



            this.setState({
                [event.target.name]: event.target.value
            })
        
    }

    async componentDidMount() {

        await initAxiosInterceptors();


        if (this.isEdit) {
            await axios.get(`api/Seller/GetByID/${this.id}`).then(
                response => {
                    this.setState({
                        id: response.data.id,
                        firstName: response.data.firstName,
                        lastName: response.data.lastName,
                        whatsAppNumber: response.data.whatsAppNumber,
                        codePlataforma: response.data.codePlataforma,
                        email: response.data.email,
                    });
                });
        }
    }

    CreateStyles() {
        return {                 
            float: 'right'
        }
    }

    HeaderTitle() {
        let title;

        if (this.isEdit) {
            title = 'Editando vendedor';
        }
        else {
            title ='Crea un nuevo vendedor';
        }

        return (
            <div>
                <Grid container spacing={2}>
                    <Grid item xs={6} md={6}>
                        <h4>
                            {title}
                            <small >
                                <Breadcrumbs aria-label="breadcrumb">
                                    <Typography color="inherit">
                                        Biosintex24Hs
                                        </Typography>

                                    <Link color="inherit" to="/vendedores" >
                                        <Typography color="textPrimary">Vendedores</Typography>
                                </Link>

                                </Breadcrumbs>
                            </small>
                        </h4>
                    </Grid>
                    <Grid item xs={6} md={6}>
                        <Link to="/Vendedores">
                            <Button
                                variant="default"
                                color="default"
                                size="small"
                                startIcon={<CancelIcon />}
                                style={this.CreateStyles()}
                            >
                                Cancelar
                        </Button>
                        </Link>
                    </Grid>
                </Grid>

                <hr />
            </div>
        );

        
    }



    async executeRESTCall(action) {


        if (action === "POST") {
            await axios.post('/api/Seller/', this.state)
                .then(response => {

                    this.props.history.push('/vendedores');

                    store.dispatch({
                        type: "SNACKBAR_SUCCESS",
                        message: "Operación exitosa"
                    })

                }).catch(error => {
                    store.dispatch({
                        type: "SNACKBAR_SUCCESS",
                        message: error.response.data.message
                    })
                });
        }

        if (action === "PUT") {
            await axios.put('/api/Seller/', this.state)
                .then(response => {

                    this.props.history.push('/vendedores');

                    store.dispatch({
                        type: "SNACKBAR_SUCCESS",
                        message: "Operación exitosa"
                    })

                }).catch(error => {
                    store.dispatch({
                        type: "SNACKBAR_SUCCESS",
                        message: error.response.data.message
                    })
                });
        }






    }   

    render() {

       
        return (

            <div>

                {this.HeaderTitle()}
            
                <Paper style={{ padding: '15px' }} elevation={1}>
                    <ValidatorForm ref="form"
                        onSubmit={this.onSubmit}
                        onError={errors => console.log(errors)}
                        style={{ padding: "10px" }}>               

                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <Grid container spacing={2}>         
                                    
                                        <Grid item sm={12} xs={12}>
                                            <TextValidator id="firstName"
                                                style={{ width: "100%" }}
                                                name="firstName"
                                                label="Nombre"
                                                variant="outlined"
                                                size="small"
                                                onChange={this.onChange}
                                                value={this.state.firstName}
                                                validators={['required', 'maxStringLength:50']}
                                        errorMessages={['El nombre es requerido', 'Máximo 50 carácteres']}
                                        autoFocus 
                                        autoComplete="off"
                                            />
                                        </Grid>

                                        <Grid item sm={12} xs={12}>
                                            <TextValidator id="lastName"
                                                style={{
                                                    width: "100%"
                                                }}
                                                name="lastName"
                                                label="Apellido"
                                                variant="outlined"
                                                size="small"
                                                onChange={this.onChange}
                                                value={this.state.lastName}
                                            validators={['required', 'maxStringLength:50']}
                                            errorMessages={['El apellido es requerido', 'Máximo 50 carácteres']}
                                                autoComplete="off"
                                            />
                                        </Grid>

                                        <Grid item sm={12} xs={12}>
                                            <TextValidator id="whatsAppNumber"
                                                style={{ width: "100%" }}
                                            name="whatsAppNumber"
                                                label="Número WhatsApp"
                                                variant="outlined"
                                                size="small"
                                                onChange={this.onChange}
                                            value={this.state.whatsAppNumber}
                                            validators={['required', 'maxStringLength:20']}
                                            errorMessages={['El número de WhatsApp es requerido', 'Máximo 20 carácteres']}
                                                autoComplete="off"
                                            />
                                    </Grid>   


                                    <Grid item sm={12} xs={12}>
                                        <TextValidator id="email"
                                            style={{ width: "100%" }}
                                            name="email"
                                            label="Email"
                                            variant="outlined"
                                            size="small"
                                            onChange={this.onChange}
                                            value={this.state.email}
                                            validators={['required', 'maxStringLength:50']}
                                            errorMessages={['El email es requerido', 'Máximo 50 carácteres']}
                                            autoComplete="off"
                                        />
                                    </Grid>   


                                    <Grid item sm={12} xs={12}>
                                        <TextValidator id="codePlataforma"
                                            style={{ width: "100%" }}
                                            name="codePlataforma"
                                            label="Código plataforma"
                                            variant="outlined"
                                            size="small"
                                            onChange={this.onChange}
                                            value={this.state.codePlataforma}
                                            validators={['required', 'maxStringLength:50']}
                                            errorMessages={['El código de plataforma es requerido', 'Máximo 50 carácteres']}
                                            autoComplete="off"
                                        />
                                    </Grid>   
                                    
                                    </Grid>
                            </Grid>                        

                                              
                        </Grid>

                        <Grid container spacing={2}>

                            <Grid item xs={12} md={12}>

                                <hr style={{marginTop:'30px'}}/>

                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="medium"
                                    startIcon={<SaveIcon />}
                                    type="submit"
                                    style={this.CreateStyles()}
                                >
                                    Guardar
                                </Button>
                            </Grid>
                        </Grid>

                    </ValidatorForm>
                </Paper>
            </div>
        )
    }
}