import React from 'react';
import clsx from 'clsx';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';

import Logo from '../assets/logo2.svg'

import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';


import Sidebar from './Sidebar';
import Topbar from './Topbar';

import { CustomAlert } from './UI/CustomAlert';
//import SuccessSnackbar from './UI/SuccessSnackbar ';
const drawerWidth = 230;



const useStyles = makeStyles((theme) => ({
    grow: {
        flexGrow: 1,
    },
    root: {
        display: 'flex',
    },
    appBar: {
        transition: theme.transitions.create(['margin', 'width'], {
            //easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
            
        }),
    },
    appBarShift: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },

    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    },

    sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        
    },
    drawerPaper: {
        width: drawerWidth,
        //backgroundColor: "#eee"
   
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: 'flex-end',
        //backgroundColor: "#44413b"
        
    },

    content: {
        flexGrow: 1,
        paddingTop: theme.spacing(11),
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: -drawerWidth,
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    },
    logo: {
        
        width: "75%",
    
        //marginLeft: "-200px",       
    },
   

}));

export default function PersistentDrawerLeft(props) {
    const classes = useStyles();
    const theme = useTheme();
    const [open, setOpen] = React.useState(true);


    const handleDrawerClose = () => {
        setOpen(false);
    };

    const handleDrawerOpen = () => {
        setOpen(true);
    };




    return (
        <div className={classes.root}>
            <CssBaseline />

            <Topbar handleDrawerOpen={handleDrawerOpen} drawerWidth={drawerWidth} className={clsx(classes.content, {
                [classes.appBarShift]: open,
                [classes.menuButton]: open,
                [classes.hide]: open,
            })}/>

            <Drawer
                className={classes.drawer}
                variant="persistent"
                anchor="left"
                open={open}
                classes={{
                    paper: classes.drawerPaper,
                }}
            >
                

                <div className={classes.drawerHeader}>
                    <center>
                    <img src={Logo} alt="ecommerce" className={classes.logo} style={{width:'55%'}}/>
                        </center>
                
                

                    <IconButton onClick={handleDrawerClose} className={classes.menuIcon} style={{ color:"#3949ab"}}>
                        {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                    </IconButton>
                </div>

                


                <Sidebar menu={props.menu}/>
            </Drawer>

            <main
                className={clsx(classes.content, {
                    [classes.contentShift]: open,
                })}
            >
                <div>{props.children}</div>
            </main>

       
            <CustomAlert />

        </div>
    );
}