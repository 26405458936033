import React, { Component } from 'react';
import { axiosInstance } from '../../utils/axiosInstance';


import { Sync, Description } from '@material-ui/icons/';
import { CustomDataTable } from '../../components/UI/CustomDataTable';


import Button from '@material-ui/core/Button';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import swal from 'sweetalert'
import store from '../../store';
import { CircularProgress } from '@material-ui/core';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import IconButton from '@material-ui/core/IconButton';


export class WhatsApp extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fcdLink: ''
            , fabLink: ''
            , eydLink: ''
        };

   
    } 



    

    async componentDidMount() {

        await axiosInstance.get('/api/Seller/GetWhatsAppLinks').then(
            response => {
                this.setState({
                    fcdLink: 'https://www.biosintex24hs.com.ar',
                    fabLink: response.data.fabLink,
                    eydLink: response.data.eydLink,
                });
            });
    }

   
    async handleClick(link) {

        navigator.clipboard.writeText(link);

        store.dispatch({
            type: "SNACKBAR_SUCCESS",
            message: "Link copiado al portapapeles"
        })

	}


    render() {
      
        return (
            <div>

                <p>Generación de Links para la realización de pedidos por WhatsApp</p>

                <Grid container spacing={5}>
                    <Grid item lg={4}>
                        <Paper style={{padding:'2rem'}}>
                            <h3>
                                Link FCD                             
                            </h3>

                            <br/>

                            <p>
                                {this.state.fcdLink}
                            </p>
                            { /*<Button onClick={() => this.handleClick(this.state.fcdLink)} variant="outlined" color="default" style={{marginTop:'16px'}}>                                
                                    <FileCopyIcon /> Copiar link
                                </Button> */}
                                
                            <a style={{ color: '#25d366' }} target="_blank" href={"https://wa.me/?text="+ this.state.fcdLink }>

                                <Button color="primary"
                                    aria-label="WhatsApps"
                                    startIcon={<WhatsAppIcon />}
                                    className="btn-rounded"
                                    style={{
                                        color: "#FFF",
                                        backgroundColor: '#128C7E',
                                        padding: '8px 16px 8px 16px'
                                    }}>
                                    Enviar link
                                </Button>

                            </a>

                            <br/>
                            <br/>
         



                        </Paper>
                    </Grid>
                    <Grid item lg={4}>
                        <Paper style={{ padding: '2rem' }}>
                        <h3>
                                Link FAB                            
                        </h3>

                            <br />

                            <p>
                                {this.state.fabLink}
                            </p>
                            {/*
                            <Button onClick={() => this.handleClick(this.state.fabLink)} variant="outlined" color="default" style={{ marginTop: '16px' }}>
                                <FileCopyIcon /> Copiar link
                            </Button>
                            */}

                            <a style={{ color: '#25d366' }} target="_blank" href={"https://wa.me/?text=" + this.state.fabLink}>

                                <Button color="primary"
                                    aria-label="WhatsApps"
                                    startIcon={<WhatsAppIcon />}
                                    className="btn-rounded"
                                    style={{
                                        color: "#FFF",
                                        backgroundColor: '#128C7E',
                                        padding: '8px 16px 8px 16px'
                                    }}>
                                    Enviar link
                                </Button>

                            </a>


                            <br />
                            <br />



                            </Paper>
                    </Grid>
                    <Grid item lg={4}>
                        <Paper style={{ padding: '2rem' }}>
                            <h3>
                                Link EYD                            
                            </h3>

                     
                            <br />
                            <p>
                                {this.state.eydLink}
                            </p>
                            { /*
                            <Button onClick={() => this.handleClick(this.state.eydLink)} variant="outlined" color="default" style={{ marginTop: '16px' }}>
                                <FileCopyIcon /> Copiar link
                            </Button>
                            */}
                            <a style={{ color: '#25d366' }} target="_blank" href={"https://wa.me/?text=" + this.state.eydLink}>

                                <Button color="primary"
                                    aria-label="WhatsApps"
                                    startIcon={<WhatsAppIcon />}
                                    className="btn-rounded"
                                    style={{
                                        color: "#FFF",
                                        backgroundColor: '#128C7E',
                                        padding: '8px 16px 8px 16px'
                                    }}>
                                    Enviar link
                                </Button>

                            </a>



                            <br />
                            <br />


                        </Paper>
                    </Grid>
                </Grid>          
            </div>
        );
    }
}
