import React, { Component } from 'react';
import { axiosInstance } from '../../utils/axiosInstance';


import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { Link } from "react-router-dom";
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import Grid from '@material-ui/core/Grid'
import { ValidatorForm, TextValidator, SelectValidator } from 'react-material-ui-form-validator';
import store from '../../store';
import { Paper } from '@material-ui/core';
import ImageUploader from 'react-images-upload';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import MenuItem from '@material-ui/core/MenuItem';
import { RedirectTypeSelector } from '../../components/UI/RedirectTypeSelector';



export class SliderForm extends Component {

    constructor(props) {
        super(props);

        this.state = {

            pageTypes:[],

            sliderID: 0,
            sliderTypeID: '',
            pageTypeRedirectID: '',

            productSubCategoryID: '',
            productCategoryID: '',
            laboratoryID: '',
            productBrandID: '',
            productID: '',

            reference: '',
            order: '',
            enabled: false,
            isMobile: false,
            isNonAuthorizeSlider: false,
            isNonAuthorizeSlider: false,
            defaultImage: '',
            form: new FormData(),

        };;

        this.sliderID = this.props.match.params.value;
        this.isEdit = this.props.match.params.value != undefined;
        this.onDropImage = this.onDropImage.bind(this);

        this.onSubmit = this.onSubmit.bind(this);

    }




    async onSubmit(e) {
    
        e.preventDefault();
        await this.addImage();
    }

    onChange = (event, value) => {


        if (event.target.type === "checkbox") {
            this.setState({
                [event.target.name]: event.target.checked,
            })
        }
        else {



            this.setState({
                [event.target.name]: event.target.value
            })
        }
    }

    onDropImage(pictureFiles, pictureDataURLs) {

        let form = this.state.form;
        var element = pictureFiles[0];

        form.append('image', element);

        this.setState({
            form: form,
            image: element === undefined ? '' : element.name,
        });        
    }

    addImage = async (image) => {

        this.setState({
            warningImageText: '',
        });

        var form = this.state.form;
        var validation = true;

        
        form.append('reference', this.state.reference);
        form.append('enabled', this.state.enabled);
        form.append('order', this.state.order);
        form.append('isMobile', this.state.isMobile);
        form.append('pageTypeRedirectID', this.state.pageTypeRedirectID);
        form.append('sliderTypeID', this.state.sliderTypeID);
        
        form.append('productCategoryID', this.state.productCategoryID);
        form.append('productSubCategoryID', this.state.productSubCategoryID);
        form.append('laboratoryID', this.state.laboratoryID);
        form.append('productBrandID', this.state.productBrandID);
        form.append('productID', this.state.productID);
        form.append('isNonAuthorizeSlider', this.state.isNonAuthorizeSlider);

        var response = null;

        if (this.isEdit) {

            form.append('sliderID', this.state.sliderID);

            await axiosInstance.put('/api/Slider/Update', form)
                .then(response => {

                    this.props.history.push('/slider');
                    store.dispatch({
                        type: "SNACKBAR_SUCCESS",
                        message: "Operación exitosa"
                    })

                }).catch(error => {

                    console.log("error: ", error);

                    store.dispatch({
                        type: "SNACKBAR_SUCCESS",
                        message: "Error"
                    })
                }); 
        }
        else {

            if (this.state.image === '') {
                validation = false;
                this.setState({
                    warningImageText: "Las imagen es requerida"
                });
            }
            else {



                await axiosInstance.post('/api/Slider/Save', form)
                    .then(response => {

                        this.props.history.push('/slider');
                        store.dispatch({
                            type: "SNACKBAR_SUCCESS",
                            message: "Operación exitosa"
                        })

                    }).catch(error => {
                        console.log("error: ", error);
                        store.dispatch({
                            type: "SNACKBAR_SUCCESS",
                            message: "Error"
                        })
                    });            

            }
        }
        this.setState({
            form: new FormData(),   
        });


      
    }

    async componentDidMount() {

        const pageTypesResponse = await axiosInstance.get(`/api/Slider/GetPageTypesForSelect`);               

        this.setState({

            pageTypes: pageTypesResponse.data,
        });


        if (this.isEdit) {
            await axiosInstance.get(`api/Slider/GetByID/${this.sliderID}`).then(
                response => {
                    this.setState({
                        sliderID: response.data.sliderID,
                        reference: response.data.reference,
                        enabled: response.data.enabled,
                        order: response.data.order,

                        sliderTypeID: response.data.sliderTypeID,

                        isNonAuthorizeSlider: response.data.isNonAuthorizeSlider,
                        isMobile: response.data.isMobile,
                        defaultImage: response.data.defaultImage,
                        pageTypeRedirectID: response.data.pageTypeRedirectID,

                        productID: response.data.productID == null ? '' : response.data.productID,

                        productCategoryID: response.data.productCategoryID == null ? '' : response.data.productCategoryID,
                        productSubCategoryID: response.data.productSubCategoryID == null ? '' : response.data.productSubCategoryID,
                        productBrandID: response.data.productBrandID == null ? '' : response.data.productBrandID,
                        laboratoryID: response.data.laboratoryID == null ? '' : response.data.laboratoryID,

                    });
                });
        }
    }

    CreateStyles() {
        return {            
            marginRight: '10px',
            marginBottom: '10px',
            float: 'right'
        }
    }

    Headerorder() {
        let order;

        if (this.isEdit) {
            order = 'Editando slider';
        }
        else {
            order ='Crea un nuevo slider';
        }

        return (
            <div>
                <Grid container spacing={2}>
                    <Grid item xs={6} md={6}>
                        <h4>
                            {order}
                            <small >
                                <Breadcrumbs aria-label="breadcrumb">
                                    <Typography color="inherit">
                                        Home
                                        </Typography>

                                    <Link color="inherit" to="/slider" >
                                        <Typography color="textPrimary">slider</Typography>
                                </Link>

                                </Breadcrumbs>
                            </small>
                        </h4>
                    </Grid>
                    <Grid item xs={6} md={6}>
                        <Link to="/slider">
                            <Button
                                variant="default"
                                color="default"
                                size="small"
                                startIcon={<CancelIcon />}
                                style={this.CreateStyles()}
                            >
                                Cancelar
                        </Button>
                        </Link>
                    </Grid>
                </Grid>

                <hr />
            </div>
        );

        
    }

    renderPageTypesOptions() {
        return this.state.pageTypes.map((dt, i) => {
            return (
                <MenuItem
                    label="Seleccione un tipo de página"
                    value={dt.id}
                    key={i} name={dt.text}>{dt.text}
                </MenuItem>
            );
        });
    }

    render() {

        var recommendedImageSize = "Tamaño recomendado 3000 x 680";

        if (this.state.sliderTypeID == 1) {
            if (this.state.isMobile) {
                recommendedImageSize = "Tamaño recomendado 1500 x 560";
            }    
        }


        if (this.state.sliderTypeID == 2) {
            recommendedImageSize = "Tamaño recomendado 360 x 800";
        }
        
        return (

            <div>

                {this.Headerorder()}

               
            
                    <ValidatorForm ref="form"
                onSubmit={this.onSubmit}
                onError={errors => console.log(errors)}
                style={{ padding: "10px" }}>               

                        <Grid container spacing={2} >

                        <Grid item xs={12} sm={12} md={5} lg={6} xl={4}>
                                <Paper style={{ padding: '15px' }} elevation={1}>

                                    <Grid container spacing={2} >
                       
                                        <Grid item sm={12} xs={12}>
                                        <TextValidator id="reference"
                                                style={{ width: "100%" }}
                                            name="reference"
                                                label="Referencia"
                                                variant="outlined"
                                                size="small"
                                                onChange={this.onChange}
                                            value={this.state.reference}
                                                validators={['required', 'maxStringLength:50']}
                                        errorMessages={['El nombre del laboratorio es requerido', 'Máximo 50 carácteres']}
                                        autoFocus 
                                        autoComplete="off"
                                            />
                                    </Grid>

                                        <Grid item sm={12} xs={12}>
                                        <TextValidator id="order"
                                            style={{ width: "100%" }}
                                            name="order"
                                            label="Orden"
                                            variant="outlined"
                                            size="small"
                                            onChange={this.onChange}
                                            value={this.state.order}
                                            validators={['required', 'matchRegexp:^[0-9]+$']}
                                            errorMessages={['El orden de la imagen es requerido', 'Solo números']}
                                            autoComplete="off"
                                        />
                                    </Grid>

                                     
                                        <Grid item sm={12} xs={12}>
                                        <FormControlLabel
                                            control={<Checkbox checked={this.state.enabled} onChange={this.onChange} name="enabled" />}
                                            label="Habilitado"
                                        />
                                    </Grid>

                                        <Grid item sm={12} xs={12}>
                                        <FormControlLabel
                                            control={<Checkbox checked={this.state.isMobile} onChange={this.onChange} name="isMobile" />}
                                            label="es dispositivo móvil"
                                        />
                                    </Grid>   

                                    <Grid item sm={12} xs={12}>
                                        <FormControlLabel
                                            control={<Checkbox checked={this.state.isNonAuthorizeSlider} onChange={this.onChange} name="isNonAuthorizeSlider" />}
                                            label="Banner para usuarios no registrados"
                                        />
                                    </Grid>   
                                    
                                    <Grid item sm={12} xs={12}>

                                        <SelectValidator
                                            style={{ width: "100%" }}
                                            name="sliderTypeID"
                                            variant="outlined"
                                            size="small"
                                            label="Tipo de slider"
                                            value={this.state.sliderTypeID}
                                            validators={['required']}
                                            errorMessages={['El tipo de slider es requerido']}
                                            onChange={this.onChange}>
                                            <MenuItem
                                                label="Seleccione un tipo de página"
                                                value="1"
                                                name="Horizontal">Horizontal
                                            </MenuItem>

                                            <MenuItem
                                                label="Seleccione un tipo de página"
                                                value="2"
                                                name="Vertical">Vertical
                                            </MenuItem>

                                        </SelectValidator>


                                    </Grid>



                                        <Grid item sm={12} xs={12}>

                                            <SelectValidator
                                                style={{ width: "100%" }}
                                                name="pageTypeRedirectID"
                                                variant="outlined"
                                                size="small"
                                                label="Tipo de redireccionamiento"
                                                value={this.state.pageTypeRedirectID}
                                                validators={['required']}
                                                errorMessages={['El tipo de redireccionamiento es requerido']}
                                                onChange={this.onChange}>
                                                {this.renderPageTypesOptions()}

                                            </SelectValidator>


                                    </Grid>



                                      <Grid item sm={12} xs={12}>

                                            <RedirectTypeSelector
                                                pageTypeRedirectID={this.state.pageTypeRedirectID}
                                                onChange={this.onChange}
                                                productID={this.state.productID}
                                            />
                                        </Grid>
                                     

                                        <Grid item xs={12} md={12}>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                size="medium"
                                                startIcon={<SaveIcon />}
                                                type="submit"
                                                style={this.CreateStyles()}
                                            >
                                                Guardar
                                        </Button>

                                </Grid>
                          
                          
                
                                    </Grid>

                                </Paper>
                        </Grid>

                        <Grid item xs={12} sm={12} md={7} lg={6} xl={4}>
                            <Paper style={{ padding: '15px' }} elevation={1}>

                                <Grid container spacing={2}>

                                    <Grid item sm={12} xs={12}>
                                            <center>
                                                <img src={this.state.defaultImage} style={{ width: '260px' }} />
                                            </center>
                                    </Grid>

                                    <Grid item sm={12} xs={12}>
                                        <ImageUploader
                                            withPreview
                                            withIcon={false}
                                            buttonClassName="primary-color bg-base border-circle add-Button"
                                            buttonText="Seleccione una imágen"
                                            onChange={this.onDropImage}
                                            imgExtension={['.jpg', '.gif', '.png', '.gif', '.jpeg']}
                                            maxFileSize={5242880}
                                            label={recommendedImageSize}
                                            singleImage={true}
                                        />
                                        <FormHelperText style={{ color: '#f44336' }}>{this.state.warningImageText}</FormHelperText>

                                    </Grid>

                                </Grid>
                            </Paper>
                        </Grid>




                        </Grid>

                    </ValidatorForm>
            </div>
        )
    }
}