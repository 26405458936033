import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import store from '../../store';


export class CustomAlert extends Component {


    constructor(props) {
        super(props);
        this.state = {
            alertIsOpen: false,
            message: ''
        };

        store.subscribe(() => {
            this.setState({
                alertIsOpen: true,
                message: store.getState().successSnackbarMessage
            });
        });
    }

    handleCloseAlert = e => {
        this.setState({ alertIsOpen: false });
    }
    

    render() {


        return (
            <div>
 
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    open={this.state.alertIsOpen}
                    autoHideDuration={1750}
                    onClose={this.handleCloseAlert}
                    message={this.state.message}
                    action={
                        <React.Fragment>
                            <IconButton size="small" aria-label="close" color="inherit" onClick={this.handleCloseAlert}>
                                <CloseIcon fontSize="small" />
                            </IconButton>
                        </React.Fragment>
                    }
                >

                   


                </Snackbar>
            </div>
        );
    }
}