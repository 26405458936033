import { createStore } from 'redux';



const reducer = (state = {}, action) => {

    switch (action.type) {
        case "SNACKBAR_SUCCESS":
            return {
                ...state,
                successSnackbarOpen: true,
                successSnackbarMessage: action.message
            };
        case "SNACKBAR_CLEAR":
            return {
                ...state,
                successSnackbarOpen: false,
                errorSnackbarOpen: false,
                infoSnackbarOpen: false
            };
        default:
            return state;
    }
  

    return state;
};

export default createStore(reducer, { snackBarSuccessIsOpen: false, snackBarMessage:'' });
//export default uiReducer;