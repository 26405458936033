import React, { Component } from 'react';
import { Paper, Button, CircularProgress, Grid } from '@material-ui/core';
import axios from 'axios';
import store from '../../store';
import { Search, Check } from '@material-ui/icons/';





export class Pharmacy extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isSubmitting: false,
            clientData: null,
            selected: false,
            showSelectButton: false,
        };

    } 
    
    async componentDidMount() {     
      
    }

    

    async searchClientById(pharmacy) {

        this.setState({
            isSubmitting: true,
        });


        await axios.get('/api/client/SearchClientById/' + pharmacy.clienteID + '')
            .then(response => {

                console.log("response SearchClientById: ", response.data);

                    this.setState({
                        clientData: response.data,
                        isSubmitting: false,
                        showSelectButton: true,
                    })


                    //if (response.data.clientes.clientes.length == 0) {
                    //    store.dispatch({
                    //        type: "SNACKBAR_SUCCESS",
                    //        message: "No se encontraron farmacias con este CUIT"
                    //    })
                    //}
                


                this.setState({
                    isSubmitting: false,
                })



            }).catch(error => {

                this.setState({
                    isSubmitting: false,
                })



                store.dispatch({
                    type: "SNACKBAR_SUCCESS",
                    message: error.response.data.message
                })
            });
    }

    selectFarmacy(pharmacy) {
        var data = {
            clientData: this.state.clientData
            , pharmacy: this.props.pharmacy
        };


        this.props.parentCallback(data);

	}


   
    render() {

        var pharmacy = this.props.pharmacy;

        return (
            <Paper elevation={1} style={{ padding: '10px', marginBottom:'20px'}}>
                <Grid container>
                    <Grid item xs={6}>
                        <ul>
                        <li>
                            <b>Código plataforma: </b>{pharmacy.clienteID}
                        </li>

                        <li>
                            <b>Nombre: </b>{pharmacy.nombre}
                        </li>

                        <li>
                            <b>Domicilio: </b>{pharmacy.domicilio}
                        </li>

                        <li>
                            <b>Clasificación: </b>{pharmacy.clasificacion1}
                        </li>

                    </ul>

                        <Button
                            variant="contained"
                            color="primary"
                            type="button"
                            onClick={() => this.searchClientById(pharmacy)}
                            startIcon={this.state.isSubmitting ? <CircularProgress color="inherit" size="1.5rem" /> : <Search />}
                            disabled={this.state.isSubmitting}

                        >
                            {this.state.isSubmitting ? "Buscando..." : "Buscar datos farmacia"}


                        </Button>

                    </Grid>
                    <Grid item xs={6}>

                        {this.state.showSelectButton ?

                            <Button
                                variant="contained"

                                type="button"
                                onClick={() => this.selectFarmacy(pharmacy)}
                                startIcon={<Check />}
                                style={{ float: 'right' }}

                            >
                                Seleccionar

                        </Button>

                            : null}


                    </Grid>

                </Grid>
             

                

                {
                    this.state.clientData != null ?
                        <div style={{padding:'15px'}}>
                            <h5>Vendedor</h5>
                            <ul>
                                <li>
                                    {this.state.clientData.seller} <small> ({this.state.clientData.sellerCode}) </small>
                                </li>
                            </ul>

                            <h5> Droguerías </h5>

                            <ul>

                                {
                                    this.state.clientData.drugstores.map((drugstore, key) => {
                                        return (
                                            <li key={key}>
                                                {drugstore.name}  <span style={{color:'#999'}}>({drugstore.clientCode})</span>
                                            </li>
                                        );
                                    })
                                }
                            </ul>


                        </div>

                        : null
                }




                
            </Paper>
        );
    }
}
