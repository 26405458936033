import React, { Component } from 'react';
import { axiosInstance } from '../../utils/axiosInstance';

import MenuItem from '@material-ui/core/MenuItem';
import { SelectValidator } from 'react-material-ui-form-validator';
import Grid from '@material-ui/core/Grid'


export class RedirectTypeSelector extends Component {
    constructor(props) {
        super(props);     

        this.state = {
            products: [],
            productCategories: [],
        }
    }

    async componentDidMount() {
        await this.getProducts();
        await this.getProductCategory();
    }


    async getProducts() {
        const productResponse = await axiosInstance.get(`/api/Product/GetProductsForSelect`);       

        this.setState({
            products: productResponse.data,
        });
    }

    async getProductCategory() {
        const productCategoryResponse = await axiosInstance.get(`/api/ProductCategory/GetproductCategoryForSelect`);

        this.setState({
            productCategories: productCategoryResponse.data,
        });
    }




   


    renderProductOptions() {
        return this.state.products.map((dt, i) => {
            return (
                <MenuItem
                    label="Seleccione un producto"
                    value={dt.id}
                    key={i} name={dt.text}>{dt.text}
                </MenuItem>
            );
        });
    }

    renderCategoryOptions() {
        return this.state.productCategories.map((dt, i) => {
            return (
                <MenuItem
                    label="Seleccione una categoría"
                    value={dt.id}
                    key={i} name={dt.text}>{dt.text}
                </MenuItem>
            );
        });
    }

    

    render() {

        var pageTypeRedirectID = this.props.pageTypeRedirectID;


        return (
        <div>
            

                {
                    pageTypeRedirectID === 4
                        ?
                        <Grid item sm={12} xs={12}>
                            <SelectValidator
                                style={{ width: "100%" }}
                                name="productID"
                                variant="outlined"
                                size="small"
                                label="Producto"
                                value={this.props.productID}
                                validators={['required']}
                                errorMessages={['El producto es requerido']}
                                onChange={this.props.onChange}>
                                {this.renderProductOptions()}
                            </SelectValidator>
                        </Grid>
                        :
                        null

                }         

            

            </div>

      

        
               

            );
        
    }
}