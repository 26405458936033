import React, { Fragment } from 'react';
import { Grid } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
//connect to store
import { connect } from 'react-redux';
import Hidden from '@material-ui/core/Hidden';
import ShoppingCart from '@material-ui/icons/ShoppingCart';



class TopProductCard extends React.Component {


    renderPrice() {
        const { data } = this.props;


            return (
                <div>

                    <Grid
                        container
                        justify="space-between"
                        alignItems="center"
                        style={{ marginTop: '15px', borderTop: '1px solid #eee', borderBottom: '1px solid #eee', paddingTop:'8px', paddingBottom:'8px' }}>
                        
                        <Typography inline variant="body1" align="left" className={"MuiTypography--subheading"} variant={"caption"} color="default" style={{ fontSize: '0.9em', fontWeight: '0', textDecoration: 'line-through' }}>
                            <small>PVP</small> ${data.pvpPrice.toFixed(2)}
                        </Typography>

                        <Typography inline variant="body1" color="secondary" align="right" style={{ fontSize: '0.9em', fontWeight: '900' }}>
                            -{data.biosintexDiscount}%
                        </Typography>

                        <Typography className={"MuiTypography--subheading"} variant={"caption"} color="primary" align="right" style={{ fontSize: '1.1em', fontWeight: '500' }}>
                            ${data.price.toFixed(2)}
                        </Typography>

                    </Grid>

                    </div>

                    );
	}

    renderButtonOptions() {
        const { data } = this.props;
     


  
            return (
                <div className="iron-btn-grp2 mb-sm-0 mb-5">
                  
                     
                            <div>
                                <br />
                                <Hidden smUp>
                                    <center>
                                        <Button
                                            className="btn-wrap button"
                                
                                            title="Agregar al carrito"
                                        >
                                            <ShoppingCart />
                                        </Button>
                                    </center>
                                </Hidden>
                                <Hidden xsDown>
                                    <Button  className="button btn-active w-100"
                                        buttonStyle={{ borderRadius: 15 }}
                                        style={{ borderRadius: 15 }}
                                    >
                                        Agregar al pedido
                                    </Button>
                                </Hidden>
                            </div>
                
                </div>

            );
        
    }


   render() {
       const { data, labelFirstText, labelSecondText, labelThirdText } = this.props;

       let discountBadge;
       let priceWithOutDiscount;

       
           discountBadge =
               <div className="iron-offer-badge">
               <span>
                   {}
                   <Typography style={{
                       color: '#fff'
                       , fontSize: '1.7rem'
                       , fontWeight: '800'
                       , textAlign: 'left'
                   }}>
                       {labelThirdText}
                   </Typography>

                   </span>
               </div>
         
               ;

           priceWithOutDiscount =
               <Typography className={"MuiTypography--subheading"} variant={"caption"} style={{ fontSize: '15px', color: '#444', fontWeight: '0' }}>
                   <span className="discount">
                      ${data.price}
                   </span>
               </Typography>;

      return (
          <Fragment >
              {data !== null ?
                  <Card className="iron-product-item iron-shadow position-relative hover-box-shadow">

              
                              <div style={{ display: 'flex', justifyContent: 'center' }}>
                              <CardMedia
                                      className="p-15"
                                      height="50"
                                      component="img"
                                      image={data.image}
                                      alt={data.name}
                                    style={{height:'260px'}}

                                     
                                  />
                                  </div>
                     

                      <CardContent className="iron-product-content p-15 pt-20">

                          <h5 className="mb-sm-15 mb-10">
                              {data.name}
                        </h5>

                          <p style={{
                         
                              display: '-webkit-box',
                                marginBottom:'0.6rem',
                                
                      }}
                      > 
                              {data.description} 
                          </p>

                              <p style={{
                          
                           
                              display: '-webkit-box',
                              color: '#999',
                              marginBottom: '0.6rem',
                                    
                                
                      }}
                      > 
                              {data.activeIngredient} 
                          </p>

                          {}


                          <p style={{
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              height: '2.7em',
                              display: '-webkit-box',
                              color: '#339933',
                              marginBottom: '0.6rem',


                          }}
                          >
                              {data.presentation}
                          </p>


                              {
                                  this.renderPrice()
                              }

                              
                         
                          {
                              this.renderButtonOptions()
                          }
                      
                        
                    </CardContent>

                
                      {discountBadge}




                          <div style={{ bottom: 0, left: 0, paddingLeft: '0.9375rem', paddingRight: '0.9375rem', color: '#fff', backgroundColor:'#218f72' }}>

                              <Grid
                                  container
                                  justify="space-between"
                                  alignItems="center"
                                  style={{}}>

                                  <Typography inline variant="body1" align="left" className={"MuiTypography--subheading"} variant={"caption"} color="default" style={{ fontSize: '0.9em', fontWeight: '0' }}>
                                      {labelFirstText}
                                  </Typography>



                                  <Typography className={"MuiTypography--subheading"} variant={"caption"} align="right" style={{ color: '#fff', fontSize: '1.5em', fontWeight: '900' }}>
                                      {labelSecondText}
                                  </Typography>

                              </Grid>


                          </div>
                       



                  </Card>

           


               :
               null
            }
         </Fragment>
      )
   }
}


export default TopProductCard;

          