import React, { Component } from 'react'


import DataTable from 'react-data-table-component';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import { makeStyles } from '@material-ui/core/styles';
import { Paper } from '@material-ui/core';



import { axiosInstance } from '../../utils/axiosInstance';



const paginationOptions = { rowsPerPageText: 'Filas por página', rangeSeparatorText: 'de', selectAllRowsItem: true, selectAllRowsItemText: 'Todos' };

const paginationServerOptions = { persistSelectedOnPageChange: true, persistSelectedOnSort: true };

const customStyles = {
    rows: {
        style: {
            minHeight: '35px', // override the row height
        }
    },
    headCells: {
        style: {
            paddingLeft: '4px', // override the cell padding for head cells
            paddingRight: '4px',
        },
    },
    cells: {
        style: {
            paddingLeft: '4px', // override the cell padding for data cells
            paddingRight: '4px',
        },
    },
};

const useStyles = makeStyles(theme => ({
    root: {
        padding: '12px',
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(3),
        },
    },
}));

const LinearIndeterminate = () => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <p>Espere por favor...</p>
            <LinearProgress />
        </div>
    );
};

export class CustomDataTable extends Component {

    constructor(props) {
        super(props);
        this.state = {
            pending: true,
            data: [],
            columns: props.columns,         
            totalRows: 0,
            perPage: 0,
            page: 0,
            query: '',
            sortColumn: '',
            sortdirection: '',
        };
             
    } 

    async componentDidMount() {

        var tableState = JSON.parse(localStorage.getItem(this.props.url))


        if (tableState !== null) {

            this.setState({
                perPage: tableState.perPage,
                page: tableState.page,
                query: tableState.query,
                sortColumn: tableState.sortColumn,
                sortdirection: tableState.sortdirection,
            }, () => {
                this.refreshDataTable();
            });
        }
        else {
            this.setState({
                perPage: 10,
                page: 1,
                query: ''
                }, () => {
                this.refreshDataTable();
            });

		}

    }


    onChange = async (event, value) => {
        this.setState({
            [event.target.name]: event.target.value
        }, () => {
                this.handleDataTableQueryChange();
        });   

      
    }


    async refreshDataTable() {    

        
        const { page, perPage, query, sortColumn, sortdirection } = this.state;

        this.setState({ pending: true });

        const getUrl = `${this.props.url}?page=${page}&per_page=${perPage}&query=${query}&column=${sortColumn}&sortdirection=${sortdirection}`;


        const response = await axiosInstance.get(getUrl);

        this.setState({
            pending: false,
            data: response.data.data,
            totalRows: response.data.total,
            
        });
    }

    refreshTableState() {

        var tableState = {
            perPage: this.state.perPage,
            page: this.state.page,
            query: this.state.query,
            sortColumn: this.state.sortColumn,
            sortdirection: this.state.sortdirection
		}

        localStorage.setItem(this.props.url, JSON.stringify(tableState))
	}


    handleDataTableQueryChange = async () => {

        this.setState({ pending: true });


        const { perPage, page, query } = this.state;

        const response = await axiosInstance.get(`${this.props.url}?page=1&per_page=${perPage}&query=${query}`);

        this.setState({
            pending: false,
            data: response.data.data,
            totalRows: response.data.total,
            perPage: perPage,
            page: 1,

        }, () => {
            this.refreshTableState();
        });   


    }


    handleDataTablePageChange = async page => {       

        this.setState({ pending: true });

        const { perPage, query, sortColumn, sortdirection } = this.state;

        const response = await axiosInstance.get(`${this.props.url}?page=${page}&per_page=${perPage}&query=${query}&column=${sortColumn}&sortdirection=${sortdirection}`);

        this.setState({
            pending: false,
            data: response.data.data,
            perPage: perPage,
            page: page,
            query: query
        }, () => {
            this.refreshTableState();
        });   

    }

    handleDataTablePerRowsChange = async (perPage, page) => {

        this.setState({ pending: true });

        const { query } = this.state;

        const response = await axiosInstance.get(`${this.props.url}?page=${page}&per_page=${perPage}&query=${query}`);

        this.setState({
            pending: false,
            data: response.data.data,
            perPage: perPage,
            page: page,
            query: query
        }, () => {
            this.refreshTableState();
        });   

    }

    handleSort = async (column, sortdirection) => {

        this.setState({ pending: true });

        const { page, perPage, query } = this.state;

        const response = await axiosInstance.get(`${this.props.url}?page=${page}&per_page=${perPage}&query=${query}&column=${column.selector}&sortdirection=${sortdirection}`);

        this.setState({
            pending: false,
            data: response.data.data,
            perPage: perPage,
            page: page,
            query: query,
            sortColumn: column.selector,
            sortdirection: sortdirection
        }, () => {
            this.refreshTableState();
        });   



    }










    rendersubHeaderComponent() {
        if (this.props.showHeader == true) {
            return (

                <Grid container direction="row"
                    justify="space-between"
                    alignItems="center"
                    spacing={1}>

                    <Grid item xs={12} sm={6} md={6}>

                    </Grid>

                    <Grid item xs={12} sm={6} md={6}>
                        <TextField id="query"
                            label="Búsqueda global"
                            variant="outlined"
                            size="small"
                            name="query"
                            style={{ float: 'right' }}
                            //onKeyUp={this.handleGlobalSearchInputKeyUp}
                            onChange={this.onChange}
                            value={this.state.query}
                        />
                    </Grid>



                </Grid>
            );
    }
    }


    render() {

        const { data, totalRows, perPage } = this.state;

        if (perPage != 0) {
            return (
                <Paper style={{ paddingTop: '10px' }}>
                    <DataTable
                        title="Productes"
                        columns={this.props.columns}
                        data={data}
                        noHeader
           
                        paginationDefaultPage={this.state.page}
                        paginationPerPage={perPage}
                        //selectableRows 
                        //customStyles={customStyles}
                        //Clicked={this.handleClick}
                        //Selected={this.handleChange}
                        highlightOnHover
                        selectableRowsHighlight
                        pagination
                        paginationServer
                        paginationTotalRows={totalRows}
                        paginationComponentOptions={paginationOptions}
                        paginationServerOptions={paginationServerOptions}
                        onSort={this.handleSort}
                        sortServer

                        onChangeRowsPerPage={this.handleDataTablePerRowsChange}
                        onChangePage={this.handleDataTablePageChange}


                        persistTableHead

                        progressPending={this.state.pending}
                        progressComponent={<LinearIndeterminate />}

                        subHeader
                        subHeaderComponent={this.rendersubHeaderComponent()}


                    />
                </Paper>
            );
        }
        else {
            return null;
		}
    }
}
