import React, { Component } from 'react';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { Link } from "react-router-dom";
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import axios from 'axios';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import Grid from '@material-ui/core/Grid'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import store from '../../store';
import { Paper } from '@material-ui/core';

import { getToken, initAxiosInterceptors } from '../../helpers/auth-helper';

export class RoleForm extends Component {

    constructor(props) {
        super(props);

        this.state = {
            id: 0,
            name: '',

        };;

        this.id = this.props.match.params.value;
        this.isEdit = this.props.match.params.value != undefined;
        this.onSubmit = this.onSubmit.bind(this);

    }


  
   

    async onSubmit(e) {
        if (!this.isEdit) {
            this.executeRESTCall("POST");
        }
        else {
            this.executeRESTCall("PUT");
        }

        e.preventDefault();
    }


    onChange = (event, value) => {



            this.setState({
                [event.target.name]: event.target.value
            })
        
    }

    async componentDidMount() {

        await initAxiosInterceptors();


        if (this.isEdit) {
            await axios.get(`api/RoleAdmin/GetByID/${this.id}`).then(
                response => {
                    this.setState({
                        id: response.data.id,
                        name: response.data.name,
                    });
                });
        }
    }

    CreateStyles() {
        return {                 
            float: 'right'
        }
    }

    HeaderTitle() {
        let title;

        if (this.isEdit) {
            title = 'Editando perfil';
        }
        else {
            title ='Crea un nuevo perfil';
        }

        return (
            <div>
                <Grid container spacing={2}>
                    <Grid item xs={6} md={6}>
                        <h4>
                            {title}
                            <small >
                                <Breadcrumbs aria-label="breadcrumb">
                                    <Typography color="inherit">
                                        Configuración
                                        </Typography>

                                    <Link color="inherit" to="/Marcas" >
                                        <Typography color="textPrimary">Vendedores</Typography>
                                </Link>

                                </Breadcrumbs>
                            </small>
                        </h4>
                    </Grid>
                    <Grid item xs={6} md={6}>
                        <Link to="/Vendedores">
                            <Button
                                variant="default"
                                color="default"
                                size="small"
                                startIcon={<CancelIcon />}
                                style={this.CreateStyles()}
                            >
                                Cancelar
                        </Button>
                        </Link>
                    </Grid>
                </Grid>

                <hr />
            </div>
        );

        
    }



    async executeRESTCall(action) {


        if (action === "POST") {
            await axios.post('/api/RoleAdmin/', this.state)
                .then(response => {

                    this.props.history.push('/perfiles');

                    store.dispatch({
                        type: "SNACKBAR_SUCCESS",
                        message: "Operación exitosa"
                    })

                }).catch(error => {
                    store.dispatch({
                        type: "SNACKBAR_SUCCESS",
                        message: error.response.data.message
                    })
                });
        }

        if (action === "PUT") {
            await axios.put('/api/RoleAdmin/', this.state)
                .then(response => {

                    this.props.history.push('/perfiles');

                    store.dispatch({
                        type: "SNACKBAR_SUCCESS",
                        message: "Operación exitosa"
                    })

                }).catch(error => {
                    store.dispatch({
                        type: "SNACKBAR_SUCCESS",
                        message: error.response.data.message
                    })
                });
        }






    }   

    render() {

       
        return (

            <div>

                {this.HeaderTitle()}
            
                <Paper style={{ padding: '15px' }} elevation={1}>
                    <ValidatorForm ref="form"
                        onSubmit={this.onSubmit}
                        onError={errors => console.log(errors)}
                        style={{ padding: "10px" }}>               

                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <Grid container spacing={2}>         
                                    
                                        <Grid item sm={12} xs={12}>
                                            <TextValidator id="name"
                                                style={{ width: "100%" }}
                                            name="name"
                                                label="Nombre"
                                                variant="outlined"
                                                size="small"
                                                onChange={this.onChange}
                                            value={this.state.name}
                                                validators={['required', 'maxStringLength:50']}
                                        errorMessages={['El nombre es requerido', 'Máximo 50 carácteres']}
                                        autoFocus 
                                        autoComplete="off"
                                            />
                                        </Grid>

                                   

                                    
                                    
                            </Grid>                        

                                              
                        </Grid>
                        </Grid>

                        <Grid container spacing={2}>

                            <Grid item xs={12} md={12}>

                                <hr style={{marginTop:'30px'}}/>

                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="medium"
                                    startIcon={<SaveIcon />}
                                    type="submit"
                                    style={this.CreateStyles()}
                                >
                                    Guardar
                                </Button>
                            </Grid>
                        </Grid>

                    </ValidatorForm>
                </Paper>
            </div>
        )
    }
}