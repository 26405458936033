import React, { Component } from 'react';
import { axiosInstance } from '../../utils/axiosInstance';


import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { Link } from "react-router-dom";
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import { Grid, MenuItem, FormControlLabel, Checkbox }from '@material-ui/core/'
import { ValidatorForm, TextValidator, SelectValidator } from 'react-material-ui-form-validator';
import store from '../../store';
import { Paper } from '@material-ui/core';

import TopProductCard from './TopProductCard';


const productData = {
    objectID: 7,
    name: "Lacrigestina Lágrima",
    description: "Lubricante ocular.",
    image: "https://www.biosintex24hs.com.ar/images/products/6ded78b4-d11f-4116-85bf-6c15d51e0037_5ecd60a2475b1.png",
    pvpPrice: 485,
    category: "LACRI",
    priceWithDiscount: 0,
    haveDiscount: true,
    url: "lacrigestina-lagrima",
    categoryUrl: null,
    minimumAmount: 12,
    discountLabelSecondText: "-58.75%",
    discountLabelFirstText: "Comprando 12",
    discountLabelThirdText: "12x11",
    percentage: 0,
    activeIngredient: "Hidroxipropilmetilcelulosa 300 mg + Dextran 70 100 mg",
    biosintexDiscount: 57,
    price: 208.55,
    group: "AAA07",
    lastRelease: false,
    presentation: "Solución oftálmica estéril x 15 ml"
};

export class DiscountForm extends Component {

    constructor(props) {
        super(props);

        this.state = {
            offerID: 0,
            description: '',
            
            labelFirstText: '',
            labelSecondText: '',
            labelThirdText: '',

            minimumAmount: '',
            pay: '',


            products: [],
            offerTypes: [],

            appType: 1,
            discountTypeID: 1,
            offerTypeID: '',
            productID: '',
            

            validFrom: null,
            validUntil: null,
            enabled: false,

            enabledMonday: false,
            enabledTuesday: false,
            enabledWednesday: false,
            enabledThursday: false,
            enabledFriday: false,
            enabledSaturday: false,
            enabledSunday: false,

            productData: null,
        };

        this.offerID = this.props.match.params.value;
        this.isEdit = this.props.match.params.value != undefined;
    }


    async executeRESTCall(action) {
        const url = '/api/Offer/';


        if (action === "POST") {
            await axiosInstance.post(url, this.state)
                .then(response => {

                    this.props.history.push('/Ofertas');
                    store.dispatch({
                        type: "SNACKBAR_SUCCESS",
                        message: "Operación exitosa"
                    })

                }).catch(error => {
                    store.dispatch({
                        type: "SNACKBAR_SUCCESS",
                        message: error.response.data.message
                    })
                });
        }

        if (action === "PUT") {
            await axiosInstance.put(url, this.state)
                .then(response => {

                    this.props.history.push('/Ofertas');
                    store.dispatch({
                        type: "SNACKBAR_SUCCESS",
                        message: "Operación exitosa"
                    })

                }).catch(error => {
                    store.dispatch({
                        type: "SNACKBAR_SUCCESS",
                        message: error.response.data.message
                    })
                });
        }     

    
    }
   

    onSubmit = e => {

        if (!this.isEdit) {
            this.executeRESTCall("POST");
        }
        else {
            this.executeRESTCall("PUT");
        }
    
        e.preventDefault();
    }


    onChange = (event, value) => {

        if (event.target.type === "checkbox") {
            this.setState({
                [event.target.name]: event.target.checked,
            })
        }
        else {
          

            if (event.target.name === "productID") {
                this.setState({
                    [event.target.name]: event.target.value
                }, () => {
                    this.getProductCard();
                });
            }
            else {



                this.setState({
                    [event.target.name]: event.target.value
                })
            }

        }


    }

    async getProductCard() {


        await axiosInstance.get(`/api/Offer/GetProductForOfferPreviewCard/${this.state.productID}`).then(
            response => {
                this.setState({
                    productData: response.data,        
                    });
            });


	}

    async componentDidMount() {

        const productResponse = await axiosInstance.get(`/api/Product/GetProductsForSelect/`); 

        this.setState({
            products: productResponse.data,
        });


        if (this.isEdit) {
            await axiosInstance.get(`api/Offer/GetByID/${this.offerID}`).then(
                response => {
                    this.setState({
                        offerID: response.data.offerID,
                        description: response.data.description,
                        percentage: response.data.percentage,
                        labelFirstText: response.data.labelFirstText,
                        labelSecondText: response.data.labelSecondText,
                        labelThirdText: response.data.labelThirdText,
                        minimumAmount: response.data.minimumAmount,
                        pay: response.data.pay,
                        productID: response.data.productID,
                        validUntil: response.data.validUntil,
                        validFrom: response.data.validFrom,
                        enabled: response.data.enabled,
                        
                    
                        enabledMonday: response.data.enabledMonday,
                        enabledThursday: response.data.enabledThursday,
                        enabledWednesday: response.data.enabledWednesday,
                        enabledTuesday: response.data.enabledTuesday,
                        enabledFriday: response.data.enabledFriday,
                        enabledSaturday: response.data.enabledSaturday,
                        enabledSunday: response.data.enabledSunday,
                    });
                });

            this.getProductCard();
        }
    }

    CreateStyles() {
        return {            
            marginRight: '10px',
            marginBottom: '10px',
            float: 'right'
        }
    }

    HeaderTitle() {
        let title;

        if (this.isEdit) {
            title = 'Editando oferta';
        }
        else {
            title ='Crea una nueva oferta';
        }

        return (
            <div>
                <Grid container spacing={2}>
                    <Grid item xs={6} md={6}>
                        <h4>
                            {title}
                            <small >
                                <Breadcrumbs aria-label="breadcrumb">
                                    <Typography color="inherit">
                                        Precios y ofertas
                                        </Typography>

                                    <Link color="inherit" to="/Ofertas" >
                                        <Typography color="textPrimary">Ofertas</Typography>
                                </Link>

                                </Breadcrumbs>
                            </small>
                        </h4>
                    </Grid>
                    <Grid item xs={6} md={6}>
                        <Link to="/Ofertas">
                            <Button
                                variant="default"
                                color="default"
                                size="small"
                                startIcon={<CancelIcon />}
                                style={this.CreateStyles()}
                            >
                                Cancelar
                        </Button>
                        </Link>
                    </Grid>
                </Grid>

                <hr />
            </div>
        );

        
    }


    renderProductOptions() {
        return this.state.products.map((dt, i) => {
            return (
                <MenuItem
                    label="Seleccione un producto"
                    value={dt.id}
                    key={i} name={dt.text}>{dt.text}
                </MenuItem>
            );
        });
    }

    render() {


        return (

            <div>

                {this.HeaderTitle()}

               
            
                    <ValidatorForm ref="form"
                onSubmit={this.onSubmit}
                onError={errors => console.log(errors)}
                style={{ padding: "10px" }}>               

                        <Grid container spacing={4} >

                            <Grid item xs={12} sm={12} md={5} lg={5}>
                                <Paper style={{ padding: '15px' }} elevation={1}>
                                <Grid container spacing={2} >


                                    <Grid item sm={12} xs={12}>

                                        <TextValidator id="description"
                                            style={{ width: "100%" }}
                                            name="description"
                                            label="Descripción(referencia)"
                                            variant="outlined"
                                            size="small"
                                            onChange={this.onChange}
                                            value={this.state.description}

                                            autoComplete="off"
                                        />


                                    </Grid>

                       
                                    <Grid item sm={12} xs={12}>
                                        <SelectValidator
                                            style={{ width: "100%" }}
                                            name="productID"
                                            variant="outlined"
                                            size="small"
                                            label="Producto"
                                            value={this.state.productID}
                                            validators={['required']}
                                            errorMessages={['El producto es requerido']}
                                            onChange={this.onChange}>
                                            {this.renderProductOptions()}
                                        </SelectValidator>
                                    </Grid>



                                    <Grid item sm={12} xs={12}>

                                        <TextValidator id="labelThirdText"
                                            style={{ width: "100%" }}
                                            name="labelThirdText"
                                            label="Texto superior"
                                            variant="outlined"
                                            size="small"
                                            onChange={this.onChange}
                                            value={this.state.labelThirdText}

                                            autoComplete="off"
                                        />


                                    </Grid>


                                    <Grid item sm={12} xs={12}>

                                        <TextValidator id="labelFirstText"
                                            style={{ width: "100%" }}
                                            name="labelFirstText"
                                            label="Texto inferior izquierda"
                                            variant="outlined"
                                            size="small"
                                            onChange={this.onChange}
                                            value={this.state.labelFirstText}
                                            autoComplete="off"
                                        />


                                    </Grid>

                                    <Grid item sm={12} xs={12}>

                                        <TextValidator id="labelSecondText"
                                            style={{ width: "100%" }}
                                            name="labelSecondText"
                                            label="Texto inferior derecha"
                                            variant="outlined"
                                            size="small"
                                            onChange={this.onChange}
                                            value={this.state.labelSecondText}
                               
                                            autoComplete="off"
                                        />


                                    </Grid>

                                

                                    <Grid item sm={12} xs={12}>

                                        <Grid container spacing={2}>

                                            <Grid item xs={6}>
                                                <TextValidator id="minimumAmount"
                                                    style={{ width: "100%" }}
                                                    name="minimumAmount"
                                                    label="Llevas"
                                                    variant="outlined"
                                                    size="small"
                                                    onChange={this.onChange}
                                                    value={this.state.minimumAmount}
                                                    validators={['required', 'matchRegexp:^[0-9]+$']}
                                                    errorMessages={['Llevas requerido', 'Solo números']}
                                                    autoComplete="off"
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextValidator id="pay"
                                                    style={{ width: "100%" }}
                                                    name="pay"
                                                    label="Pagas"
                                                    variant="outlined"
                                                    size="small"
                                                    onChange={this.onChange}
                                                    value={this.state.pay}
                                                    validators={['required', 'matchRegexp:^[0-9]+$']}
                                                    errorMessages={['Pagas requerido', 'Solo números']}
                                                    autoComplete="off"
                                                />
                                            </Grid>
                                        </Grid>

                                    </Grid>


                           




                                    <Grid item sm={12} xs={12}>
                                        <Grid container>
                                            <Grid item xs={6}>
                                                <TextValidator
                                                    InputLabelProps={{ shrink: true }}
                                                    label="Válido desde"
                                                    className="iron-form-input-wrap"
                                                    onChange={this.onChange}
                                                    name="validFrom"
                                                    type="date"
                                                    value={this.state.validFrom}
                                                    size="small"
                                                    placeholder="DD/MM/YYYY"
                                                    variant="outlined"
                                                    validators={['required']}
                                                    errorMessages={['El válido desde es requerido']}
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextValidator
                                                    InputLabelProps={{ shrink: true }}
                                                    label="Válido hasta"
                                                    className="iron-form-input-wrap"
                                                    onChange={this.onChange}
                                                    name="validUntil"
                                                    type="date"
                                                    value={this.state.validUntil}
                                                    size="small"
                                                    placeholder="DD/MM/YYYY"
                                                    variant="outlined"
                                                    validators={['required']}
                                                    errorMessages={['El válido hasta es requerido']}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>


                                    <Grid item sm={12} xs={12}>
                                        <FormControlLabel
                                            control={<Checkbox checked={this.state.enabled} onChange={this.onChange} name="enabled" />}
                                            label="Habilitado"
                                        />
                                    </Grid>
                                        <Grid item xs={12} md={12}>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                size="medioum"
                                                startIcon={<SaveIcon />}
                                                type="submit"
                                                style={this.CreateStyles()}
                                            >
                                                Guardar
                                        </Button>

                                </Grid>
                          
                          
                
                                </Grid>
                                </Paper>
                            </Grid>

                            <Grid item xs={12} sm={12} md={4} lg={4}>
                                <Paper style={{ padding: '15px' }} elevation={1}>

                           

                                <h5>Oferta válida para: </h5>
                                <p style={{ color: '#666' }}>Si no selecciona ningún día de la semana, la oferta sera válida para todos los días.</p>

                                <FormControlLabel
                                    control={<Checkbox checked={this.state.enabledMonday} onChange={this.onChange} name="enabledMonday" />}
                                    label="Lunes"
                                />

                                <br />

                                <FormControlLabel
                                    control={<Checkbox checked={this.state.enabledTuesday} onChange={this.onChange} name="enabledTuesday" />}
                                    label="Martes"
                                />
                                <br />
                                <FormControlLabel
                                    control={<Checkbox checked={this.state.enabledWednesday} onChange={this.onChange} name="enabledWednesday" />}
                                    label="Miércoles"
                                />
                                <br />
                                <FormControlLabel
                                    control={<Checkbox checked={this.state.enabledThursday} onChange={this.onChange} name="enabledThursday" />}
                                    label="Jueves"
                                />
                                <br />
                                <FormControlLabel
                                    control={<Checkbox checked={this.state.enabledFriday} onChange={this.onChange} name="enabledFriday" />}
                                    label="Viernes"
                                />
                                <br />
                                <FormControlLabel
                                    control={<Checkbox checked={this.state.enabledSaturday} onChange={this.onChange} name="enabledSaturday" />}
                                    label="Sábado"
                                />
                                <br />
                                <FormControlLabel
                                    control={<Checkbox checked={this.state.enabledSunday} onChange={this.onChange} name="enabledSunday" />}
                                    label="Domingo"
                                />
                            </Paper>                                
                            </Grid>

                            <Grid item xs={12} sm={6} md={3} lg={3}>
                                <div className="iron-top-products-wrapper pb-0">
                                <div className="iron-product-wrap my-0">

                                    {this.state.productData !== null ?

                                        <TopProductCard data={this.state.productData}
                                            labelFirstText={this.state.labelFirstText}
                                            labelSecondText={this.state.labelSecondText}
                                            labelThirdText={this.state.labelThirdText} />
                                        : null
                                    }

                                    </div>
                                </div>
                        </Grid>
                        </Grid>

                    </ValidatorForm>
            </div>
        )
    }
}