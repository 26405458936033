import React, { Component } from 'react';
import { axiosInstance } from '../../utils/axiosInstance';
import Chart from 'react-apexcharts'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import CurrencyFormat from 'react-currency-format';

import { RangeDatePicker, SingleDatePicker } from "react-google-flight-datepicker";
import "react-google-flight-datepicker/dist/main.css";

export class Dashboard extends Component {

    constructor(props) {
        super(props);
        this.state = {

            startDate: new Date(2021,4,1),
            endDate: new Date(2021,5,30),
            

            orderLineChartData: [],

            series: [{
                name: "Pedidos",
                data: []
            }],

            options: {
                chart: {   
                    type: 'line',
                    zoom: {
                        enabled: false
                    },

                    
                },
                dataLabels: {
                    enabled: true,
                },
                stroke: {
                    curve: 'straight'
                },
                title: {
                    text: 'Pedidos',
                    align: 'left'
                },
                grid: {
                    row: {
                        colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                        opacity: 0.5
                    },
                },
                labels: []
                //xaxis: {
                //    categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep'],
                //}
            },

            dashboardData: [],

            pieData:[],

            //-------------------------------------


            pieSeries: [],
            pieOptions: {
                chart: {
                    type: 'pie',
                    events: {
                        click: function (event, chartContext, config) {
                            //alert("Tu hermana");
                        }
                    }
                },
                title: {
                    text: 'Droguerías',
                    align: 'left'
                },
                labels: [],
            },

            //---------------------------------------


            barSeries: [{
                name:"Pedidos",
                data: []
            }
                , {
                name:"Usuarios registrados",
                data: []
                }
            ],
            barOptions: {
                title: {
                    text: 'Vendedores',
                    align: 'left'
                },
                chart: {
                    type: 'bar',
                    height: 500
                },
                plotOptions: {
                    bar: {
                        horizontal: true,
                        dataLabels: {
                            position: 'top',
                        },
                    }
                },
                dataLabels: {
                    enabled: true,
                    offsetX: -6,
                    style: {
                        fontSize: '12px',
                        colors: ['#fff']
                    }
                },
                stroke: {
                    show: true,
                    width: 1,
                    colors: ['#fff']
                },
                tooltip: {
                    shared: true,
                    intersect: false
                },
                xaxis: {
                    categories: [],
                },
            },


       
        };



    } 



    
    async componentDidMount() {
        await this.getData();      
    }

    async getData() {

        var data = {
            startDate: this.state.startDate,
            endDate: this.state.endDate
		}

        await axiosInstance.post('/api/Order/GetDashboardData/', data).then(
            response => {
                this.setState({
                    dashboardData: response.data,

                    pieSeries: response.data.ordersByDrugstores.map(a => a.value),

                    series: [
                        { name: 'Pedidos', data: response.data.ordersByDay.map(a => a.Pedidos) }
                    ],

                    barSeries: [
                        { name: 'Pedidos', data: response.data.ordersBySellers.map(a => a.value) },
                        { name: "Usuarios registrados", data: response.data.ordersBySellers.map(a => a.users) },
                    ]
                })
                this.setState(prevState => ({
                    pieOptions: {
                        ...prevState.pieOptions,
                        labels: response.data.ordersByDrugstores.map(p => p.name)
                    },

                    options: {
                        ...prevState.options,
                        labels: response.data.ordersByDay.map(p => p.date)
                    },


                    barOptions: {
                        ...prevState.barOptions,
                        xaxis: {
                            categories: response.data.ordersBySellers.map(p => p.name)
                        }
                    },
                }))
            });
	}

    async onDateChange(startdate, endDate) {


        this.setState({
            startDate: startdate,
            endDate: endDate,
            
        })

        if (endDate != null) {
            console.log("stateStartDate: ", this.state.startDate + ". StateEndDate:  ", this.state.endDate);
            await this.getData();
		}
	}

    render() {
      
        return (
            <div>

                <Grid container spacing={3}>
                    <Grid item lg={4} md={4} sm={6} xs={12}>
                        <h4>Dashboard</h4>
                        <p>Esto es lo que está ocurriendo en OpenLab</p>
                    </Grid>
                    <Grid item lg={4} md={6} sm={6} xs={12}>
                        <RangeDatePicker
                            dateFormat="DD/MM/YYYY"
                            monthFormat="DD MMM YYYY"
                            startDate={this.state.startDate }
                            endDate={this.state.endDate }
                            onChange={(startDate, endDate) => this.onDateChange(startDate, endDate)}
                        />
                    </Grid>
                    <Grid item lg={5}>
                    </Grid>
                    
                </Grid>

                <Grid container spacing={2}>
                    <Grid item lg={4} md={4} sm={6} xs={12}>
                        <Paper style={{ padding: '1rem', borderRadius: '10px' }} square={false}>
                            <h6 style={{color:'#666'}}>Ventas</h6>
                            <CurrencyFormat value={this.state.dashboardData.total} displayType={'text'} thousandSeparator={true} prefix={'$'} renderText={value => <h3>{value}</h3>} />
                            <p style={{ color: '#999', marginBottom: '0' }}>{this.state.dashboardData.orders } pedidos</p>
                        </Paper>
                    </Grid>
                    <Grid item lg={4} md={4} sm={6} xs={12}>
                        <Paper style={{ padding: '1rem', borderRadius: '10px' }} square={false}>
                            <h6 style={{ color: '#666' }}>Farmacias registradas</h6>
                            <h3>{this.state.dashboardData.pharmacys}</h3>
                            <p style={{ color: '#999', marginBottom: '0' }}>{this.state.dashboardData.users} usuarios</p>
                        </Paper>
                    </Grid>
                    <Grid item lg={4} md={4} sm={6} xs={12}>
                        <Paper style={{ padding: '1rem', borderRadius: '10px' }} square={false}>
                            <h6 style={{ color: '#666' }}>Unidades vendidas</h6>
                            <h3>{this.state.dashboardData.soldUnits}</h3>
                            <div style={{ height:'20px', color: '#999', marginBottom: '0' }}></div>

                        </Paper>
                    </Grid>
                </Grid>

                    <Grid container spacing={3}>

                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Paper style={{ padding: '1rem' }} square={false} >
                            <Chart options={this.state.options} series={this.state.series} type="line" height={320} />                    
                        </Paper>
                    </Grid>

                  
                </Grid>

                <Grid container spacing={3}>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                        <Paper style={{ padding: '1rem' }} square={false} >
                            {this.state.dashboardData.ordersByDrugstores === undefined ? null :
                                <Chart
                                    options={this.state.pieOptions}
                                    series={this.state.dashboardData.ordersByDrugstores.map(p => p.value)}
                                    type="donut"
                                    height={320}
                                />
                            }
                            <br />
                        </Paper>
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12}>

                        <Paper style={{ padding: '1rem' }} square={false} >
                            <Chart options={this.state.barOptions} series={this.state.barSeries} type="bar" height={750} />
                        </Paper>
                    </Grid>
                </Grid>

            </div>
        );
    }
}
