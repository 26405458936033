import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import { ValidatorForm, TextValidator, } from 'react-material-ui-form-validator';
import { Paper, Button, CircularProgress } from '@material-ui/core';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import VpnKey from '@material-ui/icons/VpnKey'

import UserContext from '../../userContext';
import Alert from '@material-ui/lab/Alert';

export class Login extends Component {

	constructor(props) {
		super(props);
		this.state = {
			email: '',
			password: '',
			message: '',
			isAuth: false,
			isLogin: false,
		}
	}

	static contextType = UserContext;

	async componentDidMount() {
		const { user } = this.context;

		this.setState({
			isAuth: user.isAuth,
		})		
	}

	onChange = (event, value) => {
		this.setState({
			[event.target.name]: event.target.value
		})
	}

	onSubmit = e => {

		const { loginUser } = this.context;

		console.log("login user: ", loginUser	);
	

		var data = {
			email: this.state.email,
			password: this.state.password,
		};


		this.setState({
			isLogin: true,
		});

		axios.post('/api/Auth/Login', data)
			.then(response => {
				loginUser(response.data);	
				this.setState({
					isAuth: true,
					isLogin: false,
				});
			})
			.catch(() => {

				this.setState({
					message: 'Datos incorrectos.',
					isLogin: false,
					
				});

			});

		e.preventDefault();
	}

	

	render() {
		const { user } = this.context;

		return (
			<div>
				{user.isAuth ?
					<h1>Bienvenido</h1>
					:
					<Grid container spacing={2}>
						<Grid item md={4} lg={4}>
						</Grid>
						<Grid item md={4} lg={4}>
							<ValidatorForm ref="form"
								onSubmit={this.onSubmit}
								onError={errors => console.log(errors)}
								style={{ padding: "10px" }}>

								<Paper style={{ padding: '30px', width: '100%' }}>

									<Grid container spacing={2}>

										<h4> Ingresar </h4>

										<Grid item xs={12}>

											
											<br />

											<TextValidator
												label="Email"
												onChange={this.onChange}
												name="email"
												value={this.state.email}
												size="small"
												variant="outlined"
												autoFocus
												style={{
													width: "100%"

												}}												
											/>
										</Grid>

										<Grid item xs={12}>
											<TextValidator
												required
												label="Contraseña"
												type="password"
												variant="outlined"
												size="small"
												value={this.state.password}
												onChange={this.onChange}
												name="password"
												style={{
													width: "100%"

												}}
											/>
										</Grid>

										





										<Grid item xs={12}>
											<Button
												variant="outlined"
												color="primary"
												size="large"
												type="submit"
												startIcon={this.state.isLogin ? <CircularProgress color="inherit" size="1.5rem" /> : <VpnKey />}
												disabled={this.state.isLogin}
												
											>
												{this.state.isLogin ? "Ingresando" : "Ingresar" }
											</Button>
										</Grid>
										<Grid item xs={12}>
											{
												this.state.message ?
													<Alert severity="error" className="mt-15 mb-30">Ups, algo ocurrió. {this.state.message} </Alert>
													: null
											}
										</Grid>
									</Grid>
								</Paper>

							</ValidatorForm>
						</Grid>
						<Grid item md={4} lg={4}>
						</Grid>
					</Grid>
				}
			
			</div>

		)
	}

}

export default withRouter(Login);