import React, { Component } from 'react';
import DataTable from 'react-data-table-component';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import { makeStyles } from '@material-ui/core/styles';
import { Paper } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { axiosInstance } from '../../utils/axiosInstance';

const paginationOptions = { rowsPerPageText: 'Filas por página', rangeSeparatorText: 'de', selectAllRowsItem: true, selectAllRowsItemText: 'Todos' };

const paginationServerOptions = { persistSelectedOnPageChange: true, persistSelectedOnSort: true };



const customStyles = {
    rows: {
        style: {
            minHeight: '35px', // override the row height
        }
    },
    headCells: {
        style: {
            paddingLeft: '4px', // override the cell padding for head cells
            paddingRight: '4px',
        },
    },
    cells: {
        style: {
            paddingLeft: '4px', // override the cell padding for data cells
            paddingRight: '4px',
        },
    },
};

const useStyles = makeStyles(theme => ({
    root: {
        padding: '12px',
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(3),
        },
    },
}));

const LinearIndeterminate = () => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <p>Espere por favor...</p>
            <LinearProgress />
        </div>
    );
};



const SampleExpandedComponent = ({ data }) => (

    <Grid container spacing={1} style={{ marginTop: '20px', width:'90%' }}>
        <Grid item xs={12} sm={12} md={6} lg={6}>
                <Table aria-label="simple table" size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell></TableCell>
                            <TableCell>Cantidad</TableCell>
                            <TableCell>Producto</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            data.details.map((dt, i) => {
                                return (
                                    <TableRow key={i}>
                                        <TableCell component="th" scope="row">
                                            <img height="80px" width="80px" alt={dt.product} src={dt.image} />
                                        </TableCell>
                                        <TableCell>{dt.quantity}</TableCell>
                                        <TableCell>{dt.product}</TableCell>
                                    </TableRow>
                                );
                            }
                            )}
                    </TableBody>
                </Table>
        </Grid>
     

    </Grid>

  


 

);




export class CustomDataTableExpanded extends Component {

    constructor(props) {
        super(props);
        this.state = {
            pending: true,
            data: [],
            columns: props.columns,         
            totalRows: 0,
            perPage: 10,
            page: 1,
            query: '',
            sortColumn: '',
            sortdirection: '',
        };

        this.handleGlobalSearchInputKeyUp = this.handleGlobalSearchInputKeyUp.bind(this);


        //store.subscribe(() => {
        //    this.setState({
                
        //        refreshDataTable: store.getState().refreshDataTable
        //    });
        //});
    } 

    async componentDidMount() {
        this.refreshDataTable();
    }


    async refreshDataTable() {

        const token = '1111';

        const { perPage, ProductID } = this.state;

        this.setState({ pending: true });

        const getUrl = `${this.props.url}?page=1&per_page=${perPage}&query=`;
     

        const response = await axiosInstance.get(getUrl, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });


        this.setState({
            pending: false,
            data: response.data.data,
            totalRows: response.data.total,
        });
    }

   

    handleDataTablePageChange = async page => {       

        this.setState({ pending: true });

        const { perPage, query } = this.state;

        const response = await axiosInstance.get(`${this.props.url}?page=${page}&per_page=${perPage}&query=${query}`);

        this.setState({
            pending: false,
            data: response.data.data,
            perPage: perPage,
            page: page,
            query: query
        });
    }

    handleDataTablePerRowsChange = async (perPage, page) => {

        this.setState({ pending: true });

        const { query, ProductID } = this.state;

        const response = await axiosInstance.get(`${this.props.url}?page=${page}&per_page=${perPage}&query=${query}`);

        this.setState({
            pending: false,
            data: response.data.data,
            perPage: perPage,
            page: page,
            query: query
        });
    }

    handleGlobalSearchInputKeyUp = async (e) => {

        this.setState({ pending: true });

        const { perPage, page, ProductID } = this.state;

        const query = e.target.value;

        const response = await axiosInstance.get(`${this.props.url}?page=${page}&per_page=${perPage}&query=${query}`);

        this.setState({
            pending: false,
            data: response.data.data,
            totalRows: response.data.total,
            perPage: perPage,
            page: page,
            query: query
        });
    }


    handleSort = async (column, sortdirection) => {

        this.setState({ pending: true });

        const { page, perPage, query } = this.state;

        const response = await axiosInstance.get(`${this.props.url}?page=${page}&per_page=${perPage}&query=${query}&column=${column.selector}&sortdirection=${sortdirection}`);

        this.setState({
            pending: false,
            data: response.data.data,
            perPage: perPage,
            page: page,
            query: query,
            column: column.selector,
            sortdirection: sortdirection
        });

    }


    rendersubHeaderComponent() {
        if (this.props.showHeader == true) {
            return (

                <Grid container direction="row"
                    justify="space-between"
                    alignItems="center"
                    spacing={1}>

                    <Grid item xs={12} sm={6} md={6}>

                    </Grid>

                    <Grid item xs={12} sm={6} md={6}>
                        <TextField id="outlined-basic"
                            label="Búsqueda global"
                            variant="outlined"
                            size="small"
                            style={{ float: 'right' }}
                            onKeyUp={this.handleGlobalSearchInputKeyUp}
                        />
                    </Grid>



                </Grid>
            );
    }
    }


    render() {

        const { data, totalRows } = this.state;

        return (
            <Paper style={{ paddingTop: '10px'}}>
            <DataTable
                    title="Productes"
                    columns={this.props.columns}
                    data={data}
                    noHeader
                    //selectableRows 
                    //customStyles={customStyles}
                    //Clicked={this.handleClick}
                    //Selected={this.handleChange}
                    expandableRows
                    highlightOnHover
                    selectableRowsHighlight
                    pagination
                    paginationServer
                    paginationTotalRows={totalRows}
                    paginationComponentOptions={paginationOptions}
                    paginationServerOptions={paginationServerOptions}
                    onSort={this.handleSort}
                    sortServer

                    onChangeRowsPerPage={this.handleDataTablePerRowsChange}
                    onChangePage={this.handleDataTablePageChange}


                    persistTableHead

                    progressPending={this.state.pending}
                    progressComponent={<LinearIndeterminate />}

                    subHeader
                    subHeaderComponent={this.rendersubHeaderComponent()}
                    expandableRowsComponent={<SampleExpandedComponent handleTest={this.handleTest} />}
                    
                
            />
            </Paper>
        );
    }
}